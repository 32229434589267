import { getRequest, postRequest, putRequest } from "./setup";

export function fetchUserTests(options) {
  let url = "/users/tests";
  if (options && options.query) {
    let queryString = `?`;
    Object.entries(options.query).forEach(([key, value]) => {
      if (value !== null && value !== undefined)
        queryString += `&${key}=${value}`;
    });
    url += queryString;
  }
  return getRequest(url);
}

export function fetchUserTest(testAttemptUid) {
  return getRequest(`/users/tests/${testAttemptUid}`);
}

export function fetchUserProfile() {
  return getRequest(`/users/profile`);
}

export function postTestResponse(body) {
  return postRequest(`/users/tests/submit`, body);
}
export function postTestQuesResponse(body) {
  return postRequest(`/users/tests/responses`, body);
}

export function postLogin(body) {
  return postRequest(`/users/auth/login`, body);
}

export function fetchTestResponses(testAttemptId) {
  return getRequest(`/users/tests/responses/${testAttemptId}`);
}

export function putStartTest(testAttemptId) {
  return putRequest(`/users/tests/attempt/start/${testAttemptId}`);
}
