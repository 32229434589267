import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

axios.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    // console.log(error.config);
    // if (error.response && error.response.status === 403) {
    //   localStorage.clear();
    //   return (window.location.href = "/");
    // }
    return Promise.reject(error);
  }
);

function getAuthToken() {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");

  if (token) return token;
  else {
    if (localStorage.getItem("authToken"))
      return localStorage.getItem("authToken");
    else return null;
  }
}

export const getRequest = (path) => {
  return axios.get(`${BASE_URL}${path}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });
};

export const postRequest = (path, data) => {
  return axios.post(`${BASE_URL}${path}`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });
};

export const patchRequest = (path, data) => {
  return axios.patch(`${BASE_URL}${path}`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });
};

export const multiGetRequests = (requests) => {
  return axios.all(requests);
};

export const multiGetRequest = async (paths) => {
  let arr = [];

  paths.forEach((item) => {
    arr.push(
      axios.get(`${BASE_URL}${item}`, {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
      })
    );
  });
  return axios.all(arr);
};

export const multiPostRequest = async (configs) => {
  let arr = [];
  const authToken = getAuthToken();
  configs.forEach((item) => {
    arr.push(
      axios.post(`${BASE_URL}${item.url}`, item.data, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
    );
  });
  return axios.all(arr);
};

export const putRequest = (path, data) => {
  return axios.put(`${BASE_URL}${path}`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });
};

export const headRequest = (path) => {
  return axios.head(`${BASE_URL}${path}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const deleteRequest = (path, body) => {
  return axios.delete(`${BASE_URL}${path}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
    data: body,
  });
};
