import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Preloader from "components-shared/Preloader";
import { fetchUserProfile } from "api/user";

import useAppStore from "stores/AppStore";
import Login from "components/Login";

import useQueryParams from "hooks/useQueryParams";

const ExamPortal = lazy(() => import("components/ExamPortal"));
const ExamPortalNew = lazy(() => import("components/ExamPortalNew"));
const UserTests = lazy(() => import("components/UserTests"));
const TestResult = lazy(() => import("components/TestResult"));
const ClosePage = lazy(() => import("components/Close Page"));
const Registration = lazy(() => import("components/Registration"));
const TermsAndCondition = lazy(() =>
  import("components/Registration/Terms&Conditions")
);
const QRcode = lazy(() => import("components/QRcode Page"));
const ThankYou = lazy(() => import("components/ThankYou Page"));

export default function AppRouter() {
  const { setUserProfile } = useAppStore((state) => ({
    setUserProfile: state.setUserProfile,
  }));

  // const { queryParams, setQueryParam } = useQueryParams();

  function getUserProfile() {
    fetchUserProfile()
      .then((resp) => {
        setUserProfile(resp.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // useEffect(() => {
  //   console.log(queryParams);
  // }, [queryParams]);

  function checkAuth(component) {
    if ("authToken" in localStorage) return component;
    else return <Login />;
  }

  useEffect(() => {
    if ("authToken" in localStorage) getUserProfile();
  }, []);

  return (
    <RouterWrapper>
      <Suspense fallback={<Preloader />}>
        <Routes>
          <Route path="/" element={checkAuth(<UserTests />)} />
          <Route path="/user/test/:testId" element={<ExamPortal />} />
          <Route path="/user-app/test/:testId" element={<ExamPortalNew />} />
          <Route path="/login" element={<Login />} />
          <Route path="/close" element={<ClosePage />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/declaration" element={<TermsAndCondition />} />
          <Route path="/QRcode" element={<QRcode />}></Route>
          <Route path="/ThankYou" element={<ThankYou />}></Route>

          <Route path="/tests/result/:testAttemptId" element={<TestResult />} />
        </Routes>
      </Suspense>
    </RouterWrapper>
  );
}

function RouterWrapper({ children }) {
  return <BrowserRouter>{children}</BrowserRouter>;
}
