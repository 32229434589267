import { postLogin } from "api/user";
import useStore from "./Login.store";

export function useLogin() {
  const { formData, setFormData, setApiResponse } = useStore((state) => ({
    formData: state.formData,
    setFormData: state.setFormData,
    setApiResponse: state.setApiResponse,
  }));

  function handleChangeForm(e) {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }

  function handleSubmitForm(e) {
    e.preventDefault();

    setApiResponse({ isLoading: true, isError: false, message: "" });
    postLogin(formData)
      .then((resp) => {
        localStorage.setItem("authToken", resp.data.data.token);
        window.location.href = "/";

        setApiResponse({ isLoading: false, isError: false, message: "" });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          console.log(err.response.status);
          setApiResponse({
            isLoading: false,
            isError: true,
            message: "*Incorrect Credentials",
          });
        }
      });
  }

  return { handleChangeForm, handleSubmitForm };
}
