import React from "react";
import style from "./Login.module.scss";

import ClipLoader from "react-spinners/ClipLoader";

import useStore from "./Login.store";
import { useLogin } from "./useLogin";

export default function Login() {
  const { apiResponse } = useStore((state) => ({
    apiResponse: state.apiResponse,
  }));

  const { handleChangeForm, handleSubmitForm } = useLogin();

  return (
    <div className={style.wrapper}>
      <form onChange={handleChangeForm} onSubmit={handleSubmitForm}>
        <h1>Please Login to access tests</h1>
        <div className={style.row}>
          <label>Username</label>
          <input type="text" name="username" />
        </div>
        <div className={style.row}>
          <label>Password</label>
          <input type="password" name="password" />
        </div>
        <div className={style.row}>
          <button disabled={apiResponse.isLoading}>
            {apiResponse.isLoading ? (
              <ClipLoader color={"white"} loading={true} size={10} />
            ) : (
              "Login"
            )}
          </button>
        </div>
        {apiResponse.isError && (
          <p className={style.error}>{apiResponse.message}</p>
        )}
      </form>
    </div>
  );
}
